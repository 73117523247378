import { useEffect } from "react";
import { formatTime } from "../Utilities.js";
import '../styles/Timer.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import ResetIcon from '@mui/icons-material/Refresh';

export const Timer = ({ id,
                        name,
                        startingTime,
                        stopwatchOrTimer,
                        firstActiveTimer,
                        immediatelyStart,
                        deleteTimerStopwatch,
                        setActiveTimerAlarm
                    }) => {

  const {start, stop, reset, isRunning, isAlarmActive, totalTime} = stopwatchOrTimer({startingTime, immediatelyStart});
  const formattedTime = formatTime(totalTime());
  
  if (firstActiveTimer) {
    if (document.title !== formattedTime) {
      document.title = formattedTime;
    }
  }

  function playPauseButtonClicked() {
    if (isRunning) {
      stop();
    } else {
      start();
    }
  }

  function deleteTimer() {
    if (totalTime() <= 0) {
      setActiveTimerAlarm(false);
    }

    deleteTimerStopwatch(id);
  }

  useEffect(() => {
    if (isAlarmActive) {
      setActiveTimerAlarm(isAlarmActive);
    }
  }, [isAlarmActive, setActiveTimerAlarm]);


  console.log(`Timer ${name} rendered. ${formatTime(totalTime)}`);



	return (
    <div className="timer-container">
      <div className="timer-name">
        {name}
      </div>
      <button 
        className="delete-button"
        onClick={() => deleteTimer()}>
        <DeleteIcon fontSize="large"/>
      </button>
      <div className="main-content">
        <div className="circle">
        </div>

        <div className="time-remaining">
          {formattedTime}
        </div>
      </div>
      <div className="button-group">
        <button onClick={playPauseButtonClicked}>
          { isRunning
            ? <PauseIcon fontSize="large"/> 
            : <PlayArrowIcon fontSize="large"/>
          }
        </button>
        <button onClick={reset}>
          <ResetIcon fontSize="large"/>
        </button>
      </div>
    </div>
	);
}

export default Timer;