export function formatTime(totalMilliseconds) {
  let timeString = '0:00';
  let tempTotalSeconds = Math.ceil(totalMilliseconds / 1000);

  if (totalMilliseconds < 0) {
    tempTotalSeconds *= -1;
  }

  const hours = Math.floor(tempTotalSeconds / 3600);
  tempTotalSeconds %= 3600;

  const minutes = Math.floor(tempTotalSeconds / 60);
  tempTotalSeconds %= 60;
  
  const seconds = Math.floor(tempTotalSeconds); 

  if (hours > 0) {
    timeString = hours + ':';
  } else {
    timeString = '';
  }

  if (minutes > 0) {
    if (minutes < 10) {
      timeString += '0'
    }
    timeString += minutes + ':';
  } else {
    timeString += '00:';
  }

  if (seconds > 0) {
    if (seconds < 10) {
      timeString += '0';
    }
    timeString += seconds;
  } else {
    timeString += '00';
  }

  if (totalMilliseconds < 0) {
    timeString = '+' + timeString;
  }

  return timeString;
};

export function getTimeString() {
  const currentDate = new Date(Date.now());
  return currentDate.toLocaleTimeString('en-US');
}

export function getFromLocalStorage(key, defaultValue) {
  const localStorageValue= localStorage.getItem(key);

  if (localStorageValue) {
    return JSON.parse(localStorageValue);
  } else {
    return defaultValue;
  }
}

export function saveToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}