import { useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { formatTime } from "../Utilities.js";
import '../styles/Sidebar.css';

export const Sidebar = ({ timersList, 
                          addActiveTimer, 
                          addActiveStopwatch,
                          resetTimerListToDefault,
                          deleteTimerFromSidebar,
                          addTimerToSidebar }) => {

  const [addTimerHours, setAddTimerHours] = useState(""); 
  const [addTimerMinutes, setAddTimerMinutes] = useState(""); 
  const [addTimerSeconds, setAddTimerSeconds] = useState(""); 
                            
  const middleMouseAddTimer = (e, timerTime) => {
    e.preventDefault()
    
    if (e.button === 1) {
      addActiveTimer(timerTime, true);
    }
  }

  const middleMouseAddStopwatch = (e) => {
    e.preventDefault()
    
    if (e.button === 1) {
      addActiveStopwatch(true)
    }
  }

  function addTimerAndResetInputs() {
    addTimer();
    resetAddTimerInputs();
  }

  function addTimer() {
    const seconds = isNaN(parseInt(addTimerSeconds)) ? 0 : parseInt(addTimerSeconds);
    const minutes = isNaN(parseInt(addTimerMinutes)) ? 0 : parseInt(addTimerMinutes);
    const hours = isNaN(parseInt(addTimerHours)) ? 0 : parseInt(addTimerHours);
    const secondsToAdd = seconds + (minutes * 60) + (hours * 60 * 60);

    addTimerToSidebar(secondsToAdd);
  }

  function resetAddTimerInputs() {
    setAddTimerHours("");
    setAddTimerMinutes("");
    setAddTimerSeconds("");
  }

  console.log("Sidebar rendered")
	return (
    <div className="sidebar-container">
      <div className="header">
        <button 
          className="icon-button"
          onClick={() => resetTimerListToDefault()}>
          <RefreshIcon fontSize="large"/>
        </button>
        Timers
      </div>
      
      <div className="timers-list">
        {timersList.map((timerTime, index) => (
          <div className="timer-button-container" 
               key={timerTime + index}
          >
            <button 
              className="timer-button"
              key={index}
              onClick={() => addActiveTimer(timerTime, false)}
              onMouseDown={(e) => middleMouseAddTimer(e, timerTime)}
            >
              {formatTime(timerTime)}
            </button>
            <button 
              className="icon-button delete-button"
              onClick={() => deleteTimerFromSidebar(timerTime)}>
              <DeleteIcon fontSize="large"/>
            </button> 
          </div>
        ))}
      </div>
        <button 
          onClick={() => addActiveStopwatch(false)}
          onMouseDown={(e) => middleMouseAddStopwatch(e)}
        >
          Stopwatch
        </button>
        <div className="add-timer-container">
          <input 
            className="margin-top add-timer-input" 
            type="text" 
            maxLength={2} 
            id="timeToAddHours"
            value={addTimerHours}
            placeholder="Hours"
            onChange={(e) => setAddTimerHours(e.target.value)}
          />
          :
          <input 
            className="margin-top add-timer-input" 
            type="text" 
            maxLength={2} 
            id="timeToAddMinutes"
            value={addTimerMinutes}
            placeholder="Minutes"
            onChange={(e) => setAddTimerMinutes(e.target.value)}
          />
          :
          <input 
            className="margin-top add-timer-input" 
            type="text" 
            maxLength={2} 
            id="timeToAddSeconds"
            value={addTimerSeconds}
            placeholder="Seconds"
            onChange={(e) => setAddTimerSeconds(e.target.value)}
          />
        </div>
        
        <button onClick={addTimerAndResetInputs}>
          Add Timer
        </button>
    </div>
	);
}

export default Sidebar;