import { useEffect, useState } from "react";

export default function useTimer({ startingTime, 
                                   immediatelyStart }) {
  const [sessionStartTime, setSessionStartTime] = useState(Date.now());
  const [totalSessionTime, setTotalSessionTime] = useState(0);
  const [totalTimeExceptSession, setTotalTimeExceptSession] = useState(0);
  const [isRunning, setIsRunning] = useState(immediatelyStart);
  const [isAlarmActive, setIsAlarmActive] = useState(false)

  let lastRenderTime = Date.now();

  function start() {
    setIsRunning(true);

    if (totalSessionTime === 0) {
      setSessionStartTime(Date.now());
    }
  }

  function stop() {
    setIsRunning(false);

    setTotalTimeExceptSession(prevTotalTimeExceptSession => prevTotalTimeExceptSession + totalSessionTime);
    setTotalSessionTime(0);
    setIsAlarmActive(false);
  }

  function reset() {
    setIsRunning(false);
    setTotalSessionTime(0);
    setTotalTimeExceptSession(0);
    setSessionStartTime(Date.now());
    setIsAlarmActive(false);
  }

  function totalTime() {
    return startingTime - totalTimeExceptSession - totalSessionTime;
  }

  function playAlarm() {
    const audio = new Audio(`/sounds/beep.mp3`);
    audio.play();
  }

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        // This prevents Timer component from rerendering ~10 times a second. Refactor?
        if ((Date.now() - lastRenderTime) >= 1000) {
          setTotalSessionTime(Date.now() - sessionStartTime);
        }
      }, 100);

      if (totalTime() < 0) {
        playAlarm();
        setIsAlarmActive(true);
      }
      return () => clearTimeout(interval);
    } 
  }, [isRunning, sessionStartTime, totalSessionTime]);

  return {start, stop, reset, isRunning, isAlarmActive, totalTime};
}