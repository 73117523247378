import { useEffect, useState } from "react";
import { getTimeString } from "../Utilities.js"

export const Header = () => {
  const [currentTime, setCurrentTime] = useState(getTimeString())
  
  useEffect(() => {
    const interval = setInterval(() => { 
      if (currentTime !== getTimeString()) {
        setCurrentTime(getTimeString()); 
      }
    }, 100);

    return () => clearTimeout(interval);
  });

  console.log("Header rendered")
	return (
    <>
      {currentTime}
    </>
	);
}

export default Header;