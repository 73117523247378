import { useEffect, useState } from "react";

export default function useStopWatch({immediatelyStart}) {
  const [sessionStartTime, setSessionStartTime] = useState(Date.now());
  const [totalSessionTime, setTotalSessionTime] = useState(0);
  const [totalTimeExceptSession, setTotalTimeExceptSession] = useState(0);
  const [isRunning, setIsRunning] = useState(immediatelyStart);

  function start() {
    setIsRunning(true);

    if (totalSessionTime === 0) {
      setSessionStartTime(Date.now());
    }
  }

  function stop() {
    setIsRunning(false);
    setTotalTimeExceptSession(prevTotalTimeExceptSession => prevTotalTimeExceptSession + totalSessionTime);
    setTotalSessionTime(0);
  }

  function reset() {
    setIsRunning(false);
    setTotalTimeExceptSession(0);
    setTotalSessionTime(0);
    setSessionStartTime(Date.now());
  }

  function totalTime() {
    return totalTimeExceptSession + totalSessionTime;
  }

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => { 
        setTotalSessionTime(Date.now() - sessionStartTime); 
      }, 100);

      return () => clearTimeout(interval);
    } 
    
  }, [isRunning, sessionStartTime, totalSessionTime]);

  return {start, stop, reset, isRunning, totalTime};
}