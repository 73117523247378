import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Timer from "./components/Timer.js";
import Header from "./components/Header.js";
import Sidebar from "./components/Sidebar.js";
import useTimer from "./hooks/useTimer.js";
import useStopwatch from "./hooks/useStopwatch.js";
import { formatTime, 
         getFromLocalStorage, 
         saveToLocalStorage } from "./Utilities.js"

import './App.css';

function App() {
  const [activeTimers, setActiveTimers] = useState([]);
  const [activeTimerAlarm, setActiveTimerAlarm] = useState(false);
  const [showAlarm, setShowAlarm] = useState(false);
  const DEFAULT_TIMERS = [60000, 300000, 600000, 900000, 1800000, 3600000, 7200000];
  const [timersList, setTimersList] = useState(getFromLocalStorage("timersList", DEFAULT_TIMERS));

  if (activeTimers.length === 0) {
    document.title = "My Timers";
  }
  
  function addActiveTimer(timerTime, immediatelyStart) {
    let tempActiveTimers = [...activeTimers];

    tempActiveTimers.push({name: formatTime(timerTime),
                           id: uuidv4(),
                           startingTime: timerTime,
                           immediatelyStart: immediatelyStart});

    setActiveTimers(tempActiveTimers);
  }

  function addActiveStopwatch(immediatelyStart) {
    let tempActiveTimers = [...activeTimers, { 
      name: "Stopwatch",
      id: uuidv4(),
      startingTime: 0,
      immediatelyStart: immediatelyStart 
    }];

    setActiveTimers(tempActiveTimers);
  }

  function deleteTimerStopwatch(id) {
    setActiveTimers(activeTimers.filter(timer => timer.id !== id));
  } 

  function resetTimerListToDefault() {
    setTimersList(DEFAULT_TIMERS);
  }

  function deleteTimerFromSidebar(timerTime) {
    setTimersList(timersList.filter(timer => timer !== timerTime));
  }

  function addTimerToSidebar(totalSeconds) {
    if (totalSeconds > 0 && !timersList.includes(totalSeconds*1000)) {
      const tempTimersList = [...timersList];

      tempTimersList.push(totalSeconds * 1000);
      tempTimersList.sort(function(a, b){return a - b});
  
      setTimersList(tempTimersList);
    }
  }

  // Refactor the way the alarm is displayed. It is clunky code.
  useEffect(() => {
    if (activeTimerAlarm) {
      const interval = setInterval(() => {
        setShowAlarm(!showAlarm);
      }, 1000);

      return () => clearTimeout(interval);
    } 
  }, [activeTimerAlarm, showAlarm]);

  useEffect(() => {
    saveToLocalStorage('timersList', timersList);
  }, [timersList]);

  console.log("App rendered")

  return (
    <div className={`container ${activeTimerAlarm && showAlarm ? "alarm" : ""}`}>
      <div>
        <div className="text-size-large centered">
          <Header />
        </div>
        <div className="active-timers">
          { activeTimers.map((timer, index) => (
            <div 
              className="active-timer" 
              key={timer.id}
            >
              <Timer 
                id={timer.id}
                name={timer.name}
                startingTime={timer.startingTime} 
                stopwatchOrTimer={timer.name === "Stopwatch" ? useStopwatch : useTimer}
                firstActiveTimer={index === 0}
                immediatelyStart={timer.immediatelyStart}
                deleteTimerStopwatch={deleteTimerStopwatch}
                setActiveTimerAlarm={setActiveTimerAlarm}
              />
            </div>
          ))}
        </div>
      </div>
      <Sidebar 
        addActiveTimer={addActiveTimer}
        addActiveStopwatch={addActiveStopwatch}
        timersList={timersList}
        resetTimerListToDefault={resetTimerListToDefault}
        deleteTimerFromSidebar={deleteTimerFromSidebar}
        addTimerToSidebar={addTimerToSidebar} />
    </div>
  );
}

export default App;